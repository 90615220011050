
.rbc-calendar {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *::before,
.rbc-calendar *::after {
  box-sizing: inherit;
}

.rbc-abs-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.rbc-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

//.rbc-off-range {
//  color: $out-of-range-color;
//}
//
//.rbc-off-range-bg {
//  background: $out-of-range-bg-color;
//}

.rbc-header {
  overflow: hidden;
  min-height: 0;
  flex: 1 0 0%;
  //padding: ($spacer * 0.5) ($spacer * 0.5);
  //border-bottom: 1px solid $cell-border;
  font-size: 90%;
  //font-weight: $font-weight-bold;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;


}

.rbc-button-link {
  padding: 0;
  border: none;
  margin: 0;
  background: none;
  color: inherit;
  cursor: pointer;
  user-select: text;
}

.rbc-row-content {
  position: relative;
  z-index: 0;
  user-select: none;
}

.rbc-row-content-scrollable {
  display: flex;
  height: 100%;
  flex-direction: column;

  .rbc-row-content-scroll-container {
    height: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

